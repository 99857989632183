
// IonAvatar,  IonThumbnail, IonItem
import {
  IonPage,
  IonContent,
  loadingController,
  alertController,
  IonInput,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonFooter,
  IonRefresher,
  IonRefresherContent,
  IonImg,
  modalController,
  actionSheetController,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../components/Empty.vue";
import ImageDetail from "../../../components/ImageDetail.vue";
import { Analytics } from "../../../common/analytics";
import { useStore } from "vuex";

/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    Empty,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonFooter,
    IonRefresher,
    IonRefresherContent,
    IonImg,
  },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const screenName = `PAGE-POST_${props.id}`;
    const analytics = new Analytics();

    const state = reactive({
      comment: {
        loading: true,
        items: [{}],
        id: 0,
        page: 1,
        size: 20,
        totalItems: 0,
        totalPages: 0,
      },
      user: {
        loading: true,
        data: {
          id: 0,
        },
      },
      post: {
        loading: true,
        data: {
          id: 0,
          like: 0,
          likeCount: 0,
          commentCount: 0,
          content: {
            file: [],
          },
          user: {
            id: 0,
          },
        },
      },
      form: {
        content: "",
      },
      commentnum: {
        totalItems: 0,
      },
      contentLoading: true,
      isScrollDisabled: false,
    });

    // methods

    const fetchComment = async (event: any = null, loadMore = false) => {
      const params = {
        size: state.comment.size,
        page: state.comment.page,
      };

      await services.commentPostFindone(props.id, params).then(
        (response) => {
          const { data } = response;
          if (event) {
            if (event.type == "ion-refresh") {
              state.comment.items = [];
            }
          }

          if (loadMore) {
            console.log("s");
            data.items.some((item: object) => {
              state.comment.items.push(item);
            });
          } else {
            data.items.some((item: object) => {
              state.comment.items.push(item);
            });
          }
          state.comment.totalItems = data.totalItems;
          state.comment.totalPages = data.totalPages;
          state.comment.loading = false;
          state.comment.page++;
          // state.comment.id = data[0].id;
          if (event) {
            if (data.items.length == 0 && loadMore) {
              state.isScrollDisabled = true;
            }
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.comment.items = [];
            }
          }
          state.isScrollDisabled = true;
          state.comment.loading = false;
        }
      );

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const fetch = async (event: any = null) => {
      await services.authUser().then(
        (response) => {
          const { data } = response;

          state.user.data = data;
          state.user.loading = false;
          console.log(state.user.data);
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          console.log(message);
        }
      );

      await services.postFindones(props.id).then(
        (response) => {
          const { data } = response;
          data.content.text = data.content.text.replace(/\n/g, "<br />");
          state.post.data = data;
          state.post.loading = false;
          fetchComment(event);
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(() => {
            if (event) {
              event.target.complete();
            }
          }, 500);

          state.post.loading = false;
          state.contentLoading = false;
          state.isScrollDisabled = true;
          state.comment.loading = false;
          console.log(message);
        }
      );
    };

    const presentConfirm = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
          {
            text: "취소",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const onSubmit = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "등록 중입니다.",
      });
      await loading.present();

      const params = {
        content: state.form.content,
        postId: props.id,
      };
      services
        .commentAdd(params)
        .then((response) => {
          console.log(response);
          const { data } = response;

          state.form.content = "";

          if (response.status == 200) {
            setTimeout(function () {
              loading.dismiss();
              state.comment.items.unshift(data);
              state.post.data.commentCount = state.post.data.commentCount + 1;
              state.comment.totalItems = state.comment.totalItems + 1;
            }, 1000);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        })
        .catch((error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        });
    };

    const onRemove = async () => {
      presentConfirm("정말 삭제 하시겠습니까?", async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "삭제 중입니다.",
        });
        await loading.present();

        services
          .postRemove(state.post.data.id)
          .then((response) => {
            console.log(response);
            if (response.status == 204) {
              setTimeout(function () {
                loading.dismiss();
                presentAlert("삭제 되었습니다", async () => {
                  window.history.length > 1 ? router.go(-1) : router.push("/");
                });
              }, 1000);
            } else {
              setTimeout(function () {
                loading.dismiss();
              }, 1000);
            }
          })
          .catch((error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            setTimeout(function () {
              loading.dismiss();
              presentAlert(message);
            }, 1000);
          });
      });
    };

    const onRemoveComment = async (index: number, id: number) => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "삭제 중입니다.",
      });
      await loading.present();
      services
        .commentRemove(id)
        .then((response) => {
          console.log(response);
          if (response.status == 204) {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
            state.comment.items.splice(index, 1);
            state.comment.totalItems = state.comment.totalItems - 1;
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        })
        .catch((error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        });
    };

    const onUserLink = (id: number) => {
      /*
      router.push({
        name: "UserId",
        params: {id: id}
      })
      */
    };

    const reportLink = (tpye: string, id: number) => {
      store.commit("report", {
        tpye: tpye,
        id: id,
      });
      router.push({
        name: "SettingReport",
        params: { id: id },
      });
    };

    const onUserBlock = (id: number) => {
      presentConfirm("정말 사용자를 차단 하시겠습니까?", async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "차단 중입니다.",
        });
        await loading.present();

        services
          .blockAdd({
            userId: id,
          })
          .then((response) => {
            console.log(response);
            if (response.status == 200) {
              setTimeout(function () {
                loading.dismiss();
                presentAlert("사용자를 차단했습니다.", async () => {
                  window.history.length > 1 ? router.go(-1) : router.push("/");
                });
              }, 1000);
            } else {
              setTimeout(function () {
                loading.dismiss();
              }, 1000);
            }
          })
          .catch((error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            setTimeout(function () {
              loading.dismiss();
              presentAlert(message);
            }, 1000);
          });
      });
    };

    async function onReport(index: number, item: any, type: string) {
      const actionSheetButton = [];
      if (type == "comment") {
        if (item.user.id == state.user.data.id) {
          actionSheetButton.push({
            text: "삭제하기",
            role: "destructive",
            handler: () => {
              onRemoveComment(index, item.id);
            },
          });
        } else {
          actionSheetButton.push({
            text: "댓글 신고하기",
            handler: () => {
              reportLink("comment", item.id);
            },
          });

          actionSheetButton.push({
            text: "사용자 신고하기",
            handler: () => {
              reportLink("user", item.user.id);
            },
          });

          actionSheetButton.push({
            text: "사용자 차단하기",
            handler: () => {
              onUserBlock(item.user.id);
            },
          });
        }
      } else if (type == "post") {
        actionSheetButton.push({
          text: "게시글 신고하기",
          handler: () => {
            reportLink("post", state.post.data.id);
          },
        });

        actionSheetButton.push({
          text: "사용자 신고하기",
          handler: () => {
            reportLink("user", state.post.data.user.id);
          },
        });

        actionSheetButton.push({
          text: "사용자 차단하기",
          handler: () => {
            onUserBlock(state.post.data.user.id);
          },
        });
      }

      actionSheetButton.push({ text: "취소", role: "cancel" });

      const actionSheet = await actionSheetController.create({
        buttons: actionSheetButton,
      });
      await actionSheet.present();
    }

    //하트 추가
    const onLike = async () => {
      console.log(state.post.data.id);

      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "잠시 기다려주세요.",
      });
      await loading.present();

      const params = {
        postId: state.post.data.id,
      };
      if (state.post.data.like == 0) {
        services.likeAdd(params).then(
          (response) => {
            console.log(response);
            state.post.data.like = 1;
            state.post.data.likeCount = state.post.data.likeCount + 1;
            loading.dismiss();
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }

            setTimeout(function () {
              loading.dismiss();
              presentAlert(message);
            }, 1000);
          }
        );
      } else {
        services.likeRemove(params).then(
          (response) => {
            console.log(response);
            state.post.data.like = 0;
            state.post.data.likeCount = state.post.data.likeCount - 1;
            loading.dismiss();
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }

            setTimeout(function () {
              loading.dismiss();
              presentAlert(message);
            }, 1000);
          }
        );
      }
    };

    const onImageDetail = async (position = 0) => {
      const items: object[] = [];
      state.post.data.content.file.some((item: object) => {
        items.push(item);
      });

      const modal = await modalController.create({
        component: ImageDetail,
        componentProps: {
          items: items,
          position: position,
          screenName: `MODAL-IMAGE-PAGE-POST_${props.id}`,
        },
      });
      return modal.present();
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.post.loading = true;
      state.comment.loading = true;
      state.comment.page = 1;
      state.isScrollDisabled = false;
      fetch(event);
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetchComment(event, true);
      }, 500);
    };

    onMounted(() => {
      state.comment.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return {
      state,
      onSubmit,
      onUserLink,
      onLike,
      onRemoveComment,
      onRefresh,
      onLoadData,
      onRemove,
      onImageDetail,
      onReport,
    };
  },
});
